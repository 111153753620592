<template>
  <div>
    <v-dialog v-model="dialog" width="500px" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          Add Task Group
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row>
            <v-col>
              <v-form ref="form" @submit.prevent :lazy-validation="false">
                <v-text-field
                  ref="groupNameInput"
                  v-model="name.value"
                  outlined
                  dense
                  label="Group Name"
                  maxlength="200"
                  autofocus
                  counter
                  :rules="name.rules"
                  :error="name.errors.length != 0"
                  :error-messages="name.errors"
                >
                </v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="close()"> cancel</v-btn>
          <v-btn
            class="msaBlue white--text"
            :disabled="disableSave"
            @click="validate()"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="msaBlue white--text"
          v-bind="attrs"
          v-on="on"
          @click="showDialog()"
          data-testid="add-task-group-template"
        >
          <v-icon> mdi-folder-plus-outline </v-icon>
        </v-btn>
      </template>
      <span> Add Task Group</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      name: {
        value: '',
        errors: [],
        rules: [(v) => !!v || 'Group name is required'],
      },
    };
  },
  watch: {
    'name.value': function (nv) {
      //clear errors for case when the error comes from the server.
      if (nv && nv.length && this.name.errors.length) {
        this.name.errors = [];
      }
    },
  },
  computed: {
    disableSave() {
      if (!this.name.value) {
        return true;
      }
      return false;
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
      if (this.$refs.groupNameInput) {
        this.$refs.groupNameInput.focus();
      }
    },
    close() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.name.errors = [];
      this.dialog = false;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.add();
      }
    },
    add() {
      const params = {
        loaderText: 'Adding group...',
        name: this.name.value,
        disableAutoError: true,
      };
      this.$axios
        .post('add-task-group-template?format=json', params)
        .then((response) => {
          this.$emit('add', response.data);
          this.close();
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.name.errors = [error.response.data];
          }
        });
    },
  },
};
</script>
