<template>
  <v-card>
    <v-card-title class="msaGrey"> Groups </v-card-title>
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-simple-table
            :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
          >
            <template v-slot:default>
              <colgroup>
                <col span="1" />
                <col span="1" :style="{ width: '155px' }" />
              </colgroup>

              <v-slide-y-transition group tag="tbody">
                <tr v-for="(group, index) in groups" :key="group.id">
                  <td class="msaBlue--text">
                    <span
                      @click="$emit('goToGroup', group.id)"
                      :style="{ cursor: 'pointer' }"
                    >
                      {{ group.name }}
                    </span>
                  </td>
                  <td class="pl-0 pr-1" align="right" v-if="!isOrdering">
                    <EditTaskGroupTemplateDialog
                      :group="group"
                      @saved="
                        $emit('updateGroup', {
                          payload: $event,
                          index: index,
                        })
                      "
                    />
                    <v-tooltip bottom v-if="!isFilterApplied">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-blur
                          text
                          small
                          v-on="on"
                          @click="
                            $emit('isOrdering');
                            group.isOrdering = true;
                          "
                        >
                          <v-icon> mdi-swap-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>Change Position</span>
                    </v-tooltip>
                    <TaskTemplateGroupDeleteDialog
                      :group="group"
                      @deleted="$emit('deleteGroup', index)"
                    />
                  </td>
                  <td v-if="isOrdering && !group.isOrdering"></td>
                  <td v-if="group.isOrdering" class="pl-0 pr-1" align="right">
                    <ChangeOrderButtonGroup
                      :currentIndex="index"
                      :maxIndex="groups.length - 1"
                      @updatePositions="
                        $emit('updateGroupPositions');
                        group.isOrdering = false;
                      "
                      @onOrder="$emit('onOrder', $event)"
                    />
                  </td>
                </tr>
              </v-slide-y-transition>
            </template>
          </v-simple-table>
          <v-col align="right" v-if="!isOrdering">
            <AddTaskGroupTemplateDialog @add="$emit('addGroup', $event)" />
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TaskTemplateGroupDeleteDialog from '@/components/TrainingTasks/TaskTemplateGroupDeleteDialog.vue';
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';
import AddTaskGroupTemplateDialog from '@/components/TrainingTasks/AddTaskGroupTemplateDialog.vue';
import EditTaskGroupTemplateDialog from '@/components/TrainingTasks/EditTaskGroupTemplateDialog.vue';

export default {
  components: {
    TaskTemplateGroupDeleteDialog,
    ChangeOrderButtonGroup,
    AddTaskGroupTemplateDialog,
    EditTaskGroupTemplateDialog,
  },
  props: {
    groups: {
      type: Array,
    },
    isOrdering: {
      type: Boolean,
    },
    isFilterApplied: {
      type: Boolean,
    },
  },

  methods: {},
};
</script>

<style></style>
