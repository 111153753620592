<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <SearchBar
          ref="searchBar"
          v-if="!isOrdering"
          @search="onSearch"
          searchLabel="Search by task name..."
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink"> </v-col>
      <v-col class="shrink"> </v-col>
      <v-col class="shrink">
        <TaskTemplateFilter
          @applyFilters="onApplyFilters"
          ref="filters"
          :disableFilter="isOrdering"
        />
      </v-col>
      <v-col class="shrink">
        <TaskTemplateExport :filters="filters" />
      </v-col>
    </v-row>
    <v-row v-if="isLoading">
      <v-col>
        <v-progress-linear indeterminate color="msaBlue"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="resultMessage.length">
      <v-col align="center"> {{ resultMessage }} </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <TaskGroupTemplates
          v-if="showGroups"
          :groups="taskGroupTemplates"
          :isOrdering="isOrdering"
          :isFilterApplied="isFilterApplied"
          @updateGroup="onGroupUpdated"
          @addGroup="onGroupAdded"
          @deleteGroup="deleteGroup"
          @isOrdering="onStartOrderingGroups"
          @onOrder="changeGroupOrder"
          @updateGroupPositions="updateGroupPositions"
          @goToGroup="goToGroup($event)"
        />
      </v-col>
      <v-col cols="12" md="8">
        <v-expansion-panels v-model="expanded" multiple>
          <v-expansion-panel
            v-for="(group, index) in taskGroupTemplates"
            :key="index"
            @change="updateExpandedGroupIds(group.id, index)"
            :disabled="isOrdering"
          >
            <v-expansion-panel-header
              class="msaGrey"
              hide-actions
              v-slot="{ open }"
              :ref="'group' + group.id"
            >
              <v-row no-gutters align="center">
                <v-col class="shrink">
                  <v-icon v-if="open">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col class="grow pl-4 font-weight-medium">
                  {{ group.name }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TaskTemplatesTable
                v-if="isGroupExpanded(index)"
                :filters="filters"
                :group="group"
                :isOrdering="isOrdering"
                :isFilterApplied="isFilterApplied"
                @isOrdering="isOrdering = true"
                @doneOrdering="isOrdering = false"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-if="isFilterApplied && taskGroupTemplates.length == 0">
      <v-col cols="12" align="center">No results found</v-col>
    </v-row>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import TaskGroupTemplates from '@/components/TrainingTasks/TaskGroupTemplates.vue';
import TaskTemplatesTable from '@/components/TrainingTasks/TaskTemplatesTable.vue';
import TaskTemplateFilter from '@/components/TrainingTasks/TaskTemplateFilter.vue';
import TaskTemplateExport from '@/components/TrainingTasks/TaskTemplateExport.vue';
export default {
  name: 'TaskTemplatesPage',
  components: {
    TaskGroupTemplates,
    TaskTemplatesTable,
    SearchBar,
    TaskTemplateFilter,
    TaskTemplateExport,
  },
  data() {
    return {
      filters: JSON.parse(JSON.stringify(this.$constants.TASK_TEMPLATE_FILTER)),
      taskGroupTemplates: [],
      search: '',
      isOrdering: false,
      expanded: [],
      isLoading: false,
    };
  },
  computed: {
    showGroups() {
      return (
        !(this.isFilterApplied && this.taskGroupTemplates.length == 0) &&
        !this.isLoading
      );
    },
    resultMessage() {
      if (
        !this.isLoading &&
        !this.isFilterApplied &&
        this.taskGroupTemplates.length == 0
      ) {
        return 'Use the Add Task Group button to add a task group.';
      }
      return '';
    },
    isFilterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.TASK_TEMPLATE_FILTER)
      );
    },
    isGroupExpanded() {
      return (index) => this.expanded.includes(index);
    },
  },
  methods: {
    updateExpandedGroupIds(groupId, index) {
      const expandedGroupIds = [
        ...this.$store.getters.persistentSettings[this.$route.name]
          .expandedGroupIds,
      ];

      // at this point, the expanded variable has not been updated yet
      if (this.expanded.includes(index)) {
        const expandIndex = expandedGroupIds.indexOf(groupId);
        expandedGroupIds.splice(expandIndex, 1);
      } else {
        expandedGroupIds.push(groupId);
      }

      this.$store.commit('updatePersistentSettings', {
        key: this.$route.name,
        value: { expandedGroupIds: expandedGroupIds },
      });
    },
    onSearch(data) {
      this.filters.taskName = data;
      this.applyFilter();
    },
    onApplyFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.$refs.searchBar.search = filters.taskName;
      this.applyFilter();
    },
    setupExpanded() {
      const expandedGroupIds =
        this.$store.getters.persistentSettings[this.$route.name]
          .expandedGroupIds;

      this.taskGroupTemplates.forEach((group, index) => {
        if (expandedGroupIds.includes(group.id)) {
          // a very short delay to avoid the DOM been frozen when expanding multiple groups at once
          setTimeout(() => {
            this.expanded.push(index);
          }, 200 + index);
        }
      });
    },
    getTaskGroupTemplates() {
      this.isLoading = true;
      const params = {
        filters: this.filters,
        isFilterApplied: this.isFilterApplied ? 1 : 0,
      };
      this.$axios
        .post('get-task-group-templates?format=json', params)
        .then((response) => {
          // a very short delay to avoid the DOM been frozen when adding multiple groups at once
          response.data.forEach((group, i) =>
            setTimeout(() => {
              this.taskGroupTemplates.push(group);
            }, i),
          );
          // this is a wait after all groups have been added to the array
          setTimeout(() => {
            this.setupExpanded();
          }, 10 * response.data.length);
        })
        .catch((error) => error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    onGroupAdded(newGroup) {
      this.taskGroupTemplates.push(newGroup);
    },
    onGroupUpdated(payload) {
      this.$set(this.taskGroupTemplates, payload.index, payload.payload);
    },
    deleteGroup(index) {
      const expandedGroupIds = [
        ...this.$store.getters.persistentSettings[this.$route.name]
          .expandedGroupIds,
      ];

      const id = this.taskGroupTemplates[index].id;
      if (expandedGroupIds.includes(id)) {
        const expandIndex = expandedGroupIds.indexOf(id);
        expandedGroupIds.splice(expandIndex, 1);
        this.$store.commit('updatePersistentSettings', {
          key: this.$route.name,
          value: { expandedGroupIds: expandedGroupIds },
        });
      }
      this.taskGroupTemplates.splice(index, 1);
    },
    changeGroupOrder(event) {
      const movedItem1 = { ...this.taskGroupTemplates[event.index] };

      const movedItem2 = {
        ...this.taskGroupTemplates[event.index + event.value],
      };

      this.$set(this.taskGroupTemplates, event.index, movedItem2);
      this.$set(this.taskGroupTemplates, event.index + event.value, movedItem1);
    },
    updateGroupPositions() {
      const ids = this.taskGroupTemplates.map((group) => group.id);
      const params = {
        loaderText: 'Updating position...',
        taskGroupTemplateIds: ids,
      };

      this.$axios
        .post('update-task-group-template-positions', params)
        .then(() => (this.isOrdering = false))
        .catch((error) => error);
    },
    goToGroup(groupId) {
      const options = {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
      };
      this.$vuetify.goTo(this.$refs[`group${groupId}`][0], options);
    },
    loadQueryParams() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(
        query,
        this.$constants.TASK_TEMPLATE_FILTER,
      );
      this.$refs.searchBar.search = this.filters.taskName;
      this.$refs.filters.setFilters(this.filters);
      this.getTaskGroupTemplates();
    },
    applyFilter() {
      const params = this.$helpers.serializeFilter(this.filters);
      this.$router
        .push({
          name: this.$route.name,
          query: params,
        })
        .catch(() => {});
    },
    onStartOrderingGroups() {
      this.isOrdering = true;
      this.expanded = [];
      this.$store.commit('updatePersistentSettings', {
        key: this.$route.name,
        value: { expandedGroupIds: [] },
      });
    },
  },
  mounted() {
    this.loadQueryParams();
  },
};
</script>
