<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          <v-row class="ma-1">
            <span>Filters</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon color="white"> mdi-close </v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row dense>
            <v-col cols="12">Task Name </v-col>
            <v-col>
              <v-text-field
                v-model="filters.taskName"
                outlined
                hide-details
                placeholder="Enter task name"
                dense
                clearable
                @click:clear="onInputClear('taskName')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">Group Name </v-col>
            <v-col>
              <v-text-field
                v-model="filters.groupName"
                outlined
                hide-details
                placeholder="Enter group name"
                dense
                clearable
                @click:clear="onInputClear('groupName')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Task Types </v-col>
            <v-col cols="12">
              <v-select
                v-model="filters.taskTypeIds"
                outlined
                dense
                placeholder="Select task type"
                hide-details
                :items="taskTypes"
                item-text="name"
                item-value="id"
                clearable
                multiple
                chips
                deletable-chips
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12"> Deadline Range</v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="py-0"
                dense
                outlined
                v-model="filters.deadlineFrom"
                type="number"
                min="1"
                placeholder="From"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="py-0"
                dense
                outlined
                v-model="filters.deadlineTo"
                type="number"
                min="1"
                placeholder="To"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="clear()"> clear </v-btn>
          <v-btn class="msaBlue white--text" @click="apply"> apply </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="isFilterApplied ? 'orange' : 'msaBlue'"
          v-blur
          v-bind="attrs"
          v-on="on"
          @click="show"
          :disabled="disableFilter"
          class="white--text"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </template>
      <span>Filter</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    disableFilter: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      users: [],
      labels: [],
      taskTypes: [
        { id: 1, name: 'Regular' },
        { id: 2, name: 'Document' },
        { id: 3, name: 'Training' },
      ],
      filters: JSON.parse(JSON.stringify(this.$constants.TASK_TEMPLATE_FILTER)),
      original: JSON.parse(
        JSON.stringify(this.$constants.TASK_TEMPLATE_FILTER),
      ),
      isFilterApplied: false,
      dueDateFromMenu: false,
      dueDateToMenu: false,
    };
  },
  methods: {
    onInputClear(element) {
      this.filters[element] = '';
    },
    setFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.updateFilterButtonState();
    },
    close() {
      this.filters = { ...this.orginal };
      this.dialog = false;
    },
    show() {
      this.orginal = { ...this.filters };
      this.dialog = true;
    },
    clear() {
      this.filters = JSON.parse(
        JSON.stringify(this.$constants.TASK_TEMPLATE_FILTER),
      );
      this.apply();
    },
    apply() {
      this.filters = this.$helpers.fixFilterSpaces(
        this.$constants.TASK_TEMPLATE_FILTER,
        this.filters,
      );
      this.$emit('applyFilters', this.filters);
      this.dialog = false;
    },
    updateFilterButtonState() {
      this.isFilterApplied =
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.TASK_TEMPLATE_FILTER);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
