<template>
  <v-card>
    <v-card-text class="pa-0">
      <v-data-table
        :disable-sort="isMobile"
        :headers="headers"
        :items="taskTemplates"
        :loading="isLoading"
        :options.sync="options"
        :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
        checkbox-color="msaBlue"
        class="form-template-list"
        disable-filtering
        disable-pagination
        hide-default-footer
        item-key="id"
      >
        <template v-slot:body="{ items }" v-if="!isLoading">
          <v-slide-y-transition group tag="tbody">
            <template v-for="(item, index) in items">
              <tr :key="item.id">
                <td>
                  <v-icon :color="iconColor(item)">
                    {{ getIcon(item) }}
                  </v-icon>
                  <span v-html="taskName(item.name)"></span>
                  <span
                    v-if="item.isExpired"
                    class="red--text text-caption font-italic"
                  >
                    (Expired)
                  </span>
                </td>
                <td>
                  {{ item.updatedAt | filterAsLocalDate }}<br />
                  ({{ item.updater }})
                </td>
                <td>
                  <span v-if="!isOrdering">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="goToDetail(item)"
                          text
                          x-small
                          height="40"
                          color="msaBlue"
                          v-on="on"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="!isFilterApplied && !isSorting">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          height="40"
                          text
                          v-blur
                          v-on="on"
                          x-small
                          @click="
                            $emit('isOrdering');
                            item.isOrdering = true;
                          "
                        >
                          <v-icon> mdi-swap-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>Change Position</span>
                    </v-tooltip>
                    <TaskTemplateDeleteDialog
                      :taskTemplate="item"
                      @deleted="onTaskTemplateDeleted(index)"
                    />
                  </span>
                  <span v-if="isOrdering && !item.isOrdering"></span>
                  <span v-if="item.isOrdering" class="pl-0 pr-1" align="right">
                    <ChangeOrderButtonGroup
                      :currentIndex="index"
                      :maxIndex="taskTemplates.length - 1"
                      @updatePositions="updateTaskTemplatePositions()"
                      @onOrder="changeOrder($event)"
                    />
                  </span>
                </td>
              </tr>
            </template>
          </v-slide-y-transition>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="!isOrdering">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            class="msaBlue white--text"
            v-on="on"
            @click="goToNew(group.id)"
            :data-testid="`add-task-templates-${group.name}`"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span>Add Task</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>
<script>
import TaskTemplateDeleteDialog from '@/components/TrainingTasks/TaskTemplateDeleteDialog.vue';
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';
export default {
  components: {
    TaskTemplateDeleteDialog,
    ChangeOrderButtonGroup,
  },
  props: {
    group: {
      type: Object,
    },
    isOrdering: {
      type: Boolean,
    },
    isFilterApplied: {
      type: Boolean,
    },
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Last Update',
          value: 'updatedAt',
          align: 'center',
          width: '120px',
          class: 'lightGrey',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'lightGrey',
          sortable: false,
          width: '180px',
        },
      ],
      newFormTemplateDetails: {
        id: 0,
        name: '',
        allowAdditionalHazards: 0,
        allowTags: 1,
        description: '',
        isDuplicable: 1,
        isPrivate: 0,
        isPublished: 0,
        managerApprovalRequired: 0,
        safetyRepApprovalRequired: 0,
        supervisorApprovalRequired: 1,
        sections: [],
      },
      options: {},
      taskTemplates: [],
      isLoading: false,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    taskName() {
      return (taskName) => {
        return '<span>' + this.$helpers.linkify(taskName) + '</span>';
      };
    },
    isSorting() {
      return !!this.options?.sortBy.length;
    },
  },
  methods: {
    iconColor(task) {
      let color = '';
      switch (task.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          color = 'red';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          color = 'msaBlue';
          break;
        default:
          color = 'green';
      }
      return color;
    },
    getIcon(task) {
      let icon = '';
      switch (task.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          icon = 'mdi-file-pdf-box';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          icon = 'mdi-school';
          break;
        default:
          icon = 'mdi-checkbox-marked-outline';
      }
      return icon;
    },
    goToNew(groupId) {
      this.$store.commit('setSelectedTaskTemplateName', '');
      this.$router.push({
        name: 'TaskTemplateNew',
        params: { id: groupId },
      });
    },
    goToDetail(taskTemplate) {
      //this is here so the crumbs don't look wonky on the next page
      this.$store.commit('setSelectedTaskTemplateName', taskTemplate.name);
      this.$router.push({
        name: 'TaskTemplateDetail',
        params: { id: taskTemplate.id },
      });
    },
    updateTaskTemplatePositions() {
      const taskTemplateIds = this.taskTemplates.map((ft) => {
        if (ft.isOrdering) {
          ft.isOrdering = false;
        }
        return ft.id;
      });

      const params = {
        loaderText: 'Updating positions...',
        taskTemplateIds: taskTemplateIds,
        taskGroupTemplateId: this.group.id,
      };
      this.$axios
        .post('update-task-template-positions?format=json', params)
        .catch((error) => error)
        .finally(() => this.$emit('doneOrdering'));
    },
    changeOrder(event) {
      const movedItem1 = { ...this.taskTemplates[event.index] };
      const movedItem2 = {
        ...this.taskTemplates[event.index + event.value],
      };

      this.$set(this.taskTemplates, event.index + event.value, movedItem1);
      this.$set(this.taskTemplates, event.index, movedItem2);
    },
    getTaskTemplates() {
      this.isLoading = true;
      const params = {
        filters: this.filters,
        groupId: this.group.id,
      };
      this.$axios
        .post('get-task-templates-by-group?format=json', params)
        .then((response) => {
          // a very short delay to avoid the DOM been frozen when adding multiple groups at once
          response.data.forEach((item, i) =>
            setTimeout(() => {
              this.taskTemplates.push(item);
            }, i),
          );
        })
        .catch((error) => error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    onTaskTemplateDeleted(index) {
      this.taskTemplates.splice(index, 1);
    },
  },
  mounted() {
    this.getTaskTemplates();
  },
};
</script>

<style>
.form-template-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
