<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            x-small
            height="40"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Delete Task Group
      </v-card-title>
      <v-card-text class="mt-2 text-body-1">
        Are you sure you want to delete
        <strong>{{ group.name }}</strong
        >?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" text class="red--text"> Cancel </v-btn>

        <v-btn @click="confirm()" class="msaBlue white--text"> confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    group: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    confirm() {
      const params = {
        loaderText: 'Deleting...',
        taskGroupTemplateId: this.group.id,
      };
      this.$axios
        .post('delete-task-group-template?format=json', params)
        .then(() => {
          this.$emit('deleted');
          this.close();
        })
        .catch((error) => error);
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style></style>
